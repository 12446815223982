<script>
export default {
  data() {
    return {
      i18n_prefix: "i18n",
    };
  },

  methods: {
    __: function (
      word,
      input_prefix = null,
      { quantity = null, config = null } = {}
    ) {
      const prefix = input_prefix || this.i18n_prefix;

      return this.i18nGetTranslation(word, { prefix, quantity, config });
    },
    i18nGetPrefix(expression) {
      for (let word in this.i18n_words) {
        if (word.word === expression) {
          return word.prefix;
        }
      }

      return this.i18n_prefix;
    },
    i18nGetTranslation(expression, { prefix, quantity, config }) {
      const code = `model.${prefix}_${expression}`;
      const result = this.getTcConfig(code, { quantity, config });
      const translation =
        result === code ? expression : this.$capitalize(result);

      return translation;
    },
    getTcConfig(code, { quantity, config }) {
      if (quantity === null) {
        return this.$tc(code, 1, config);
      }

      return this.$tc(code, quantity, config);
    },
  },
};
</script>
